<template>
  <div class="app">
    <router-view v-slot="{ Component }">
      <transition name="el-fade-in" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import {computed, watch} from "vue";
import useConfigStore from "@/api/config/config.store";

const configStore = useConfigStore()

const theme = computed(() => {
  return configStore.theme
})

const html = document.querySelector('html')

watch(theme, () => {
  if (theme.value === 'default') {
    html.classList.remove('dark')
    html.classList.add('default')
  } else {
    html.classList.remove('default')
    html.classList.add('dark')
  }
})

html.classList.add(theme.value)
</script>
