import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('@/components/pages/login/LoginPage.vue')
}, {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/components/layouts/baseLayout/BaseLayout.vue'),
    children: [{
        path: '/dashboard',
        name: 'dashboard',
        meta: { title: 'Dashboard' },
        component: () => import('@/components/pages/dashboard/DashboardPage.vue')
    }, {
        path: '/domains',
        name: 'domains',
        meta: { title: 'Domains' },
        component: () => import('@/components/pages/domains/DomainsPage.vue')
    }, {
        path: '/users',
        name: 'users',
        meta: { title: 'Users' },
        component: () => import('@/components/pages/users/UsersPage.vue')
    }, {
        path: '/history',
        name: 'history',
        meta: { title: 'History' },
        component: () => import('@/components/pages/history/HistoryPage.vue')
    }]
}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    next()
})

export default router
