import { defineStore } from 'pinia'

export default defineStore({
    id: 'config',
    state: () => ({
        theme: localStorage.getItem('theme')
    }),
    actions: {
        setTheme(theme) {
            this.theme = theme
            localStorage.setItem('theme', theme)
        }
    }
})
